import React from 'react';
import Icon from '@ant-design/icons';

const DiscordSvg = () => (
  <svg width="24" height="24" viewBox="0 0 100 100" fill="currentColor">
    <path d="M42 52.5C42 49.4776 44.1354 47 46.84 47C49.545 47 51.7278 49.4776 51.6804 52.5C51.6804 55.5224 49.545 58 46.84 58C44.1828 58 42 55.5224 42 52.5ZM59.3198 52.5C59.3198 49.4776 61.4551 47 64.1601 47C66.8648 47 69 49.4776 69 52.5C69 55.5224 66.8648 58 64.1601 58C61.5026 58 59.3198 55.5224 59.3198 52.5Z" />
    <path d="M21.8401 6H86.1599C91.5839 6 96 10.5081 96 16.094V104L85.6801 94.6901L79.872 89.2021L73.7279 83.371L76.2721 92.4361H21.8401C16.4161 92.4361 12 87.928 12 82.342V16.094C12 10.5081 16.4161 6 21.8401 6ZM64.464 66.0251C65.9042 67.8871 67.6319 69.9941 67.6319 69.9941C78.2401 69.651 82.3201 62.5461 82.3201 62.5461C82.3201 46.768 75.4079 33.979 75.4079 33.979C68.496 28.6871 61.9198 28.8341 61.9198 28.8341L61.2478 29.6181C69.408 32.166 73.2 35.8411 73.2 35.8411C68.2081 33.048 63.3119 31.6761 58.7521 31.1371C55.296 30.7451 51.9841 30.843 49.0562 31.235C48.8046 31.235 48.5897 31.2723 48.3478 31.3147C48.3122 31.3205 48.2767 31.3268 48.24 31.3331C46.56 31.48 42.4799 32.1171 37.3442 34.4201C35.568 35.2531 34.5121 35.8411 34.5121 35.8411C34.5121 35.8411 38.496 31.9701 47.1358 29.422L46.6561 28.8341C46.6561 28.8341 40.0799 28.6871 33.1681 33.979C33.1681 33.979 26.2562 46.768 26.2562 62.5461C26.2562 62.5461 30.2882 69.651 40.896 69.9941C40.896 69.9941 42.6721 67.7891 44.1119 65.9271C38.0162 64.0651 35.7118 60.1451 35.7118 60.1451C35.7118 60.1451 36.192 60.488 37.0558 60.9781C37.1039 61.027 37.152 61.0761 37.2481 61.1251C37.3199 61.174 37.3919 61.2109 37.4642 61.2477C37.536 61.2842 37.608 61.3211 37.6802 61.3701C38.88 62.056 40.0799 62.595 41.184 63.0361C43.1519 63.8201 45.5039 64.6041 48.24 65.1431C51.8398 65.8291 56.0641 66.0741 60.672 65.192C62.9279 64.8 65.2322 64.1141 67.6319 63.0851C69.3118 62.448 71.184 61.5171 73.152 60.1941C73.152 60.1941 70.752 64.2121 64.464 66.0251Z" />
  </svg>
);

export default (props: any) => <Icon component={DiscordSvg} {...props} />;