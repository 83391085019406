import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import LizardIcon from './assets/logo.png';
import './App.scss';

import Home from './app/Home/Home';
import Toolbar from './components/Toolbar';

const App: FC = () => {
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsReady(true);

    setTimeout(() => {
      setIsLoading(false)
    }, 1500);
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <Layout>
      {isLoading && (
        <div className="loading-container">
          <img src={LizardIcon} alt="Lizard" />
        </div>
      )}
      <Toolbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </Layout>
  )
};

export default App;
