import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Typography, Menu } from 'antd';
import { ToolbarStyled } from './Toolbar.styled';
import useWindowSize from 'hooks/useWindowSize';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import LizardIcon from 'assets/logo-topnav.png';
import IconTwitter from 'components/icons/IconTwitter';
import IconDiscord from 'components/icons/IconDiscord';
import IconMedium from 'components/icons/IconMedium';
import IconTelegram from 'components/icons/IconTelegram';
import { Routes } from 'domain/routes';
import throttle from "lodash/throttle";
import { HomeStyled } from 'app/Home/Home.styled';
import MetamaskIcon from '../../assets/metamask-white.png';
import { OASIS_CHAIN_PARAMS } from '../../constants';
import { injected } from 'connectors';

const { Title, Link } = Typography;

export interface AppToolbarItemInterface {
  id: string,
  title: string,
  href: string
}

const LizardToolbar: FC = (): JSX.Element => {
  const { isLtXl, isLtMd } = useWindowSize();
  const history = useHistory();
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const clickMobileItem = () => {
    setShowMenuMobile(false)
  }

  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  return (
    <ToolbarStyled.LizardHeader showMenu={showMenu} style={{ display: 'block', position: 'fixed', zIndex: 9, width: '100%' }}>
      <div className="d-flex position-relative" style={{ height: '80px' }}>
        {isLtXl && (
          <>
            <ToolbarStyled.HamburgerButton>
              <Button type="ghost" shape="circle" icon={<MenuOutlined style={{ color: '#FFF' }} />} onClick={() => setShowMenuMobile(true)} />
            </ToolbarStyled.HamburgerButton>
            <ToolbarStyled.MainDrawer
              title={
                <Title level={5} className="mb-0 p-3 text-white">Lizard 🦎</Title>
              }
              placement="left"
              closable={false}
              onClose={() => setShowMenuMobile(false)}
              visible={showMenuMobile}
            >
              <Menu theme="light" mode="inline">
                <Menu.Item key="1">
                  <a href={Routes.Swap} onClick={() => clickMobileItem()}>
                    Exchange
                  </a>
                </Menu.Item>
                <Menu.Item key="2">
                  <a href={Routes.Info} target={'_blank'} onClick={() => clickMobileItem()}>
                    Analytics
                  </a>
                </Menu.Item>
                <Menu.Item key="3">
                  <a href={Routes.Bridge} target={'_blank'} onClick={() => clickMobileItem()}>
                    Bridge
                  </a>
                </Menu.Item>
                <Menu.Item key="4">
                  <a href={Routes.Docs} target={'_blank'} onClick={() => clickMobileItem()}>
                    Docs
                  </a>
                </Menu.Item>
              </Menu>
            </ToolbarStyled.MainDrawer>
            <ToolbarStyled.Socials className="d-flex align-items-center ms-auto">
              <a href="https://twitter.com/lizardexchange" target={'_blank'} rel="noreferrer" title="Twitter" className="mx-1">
                <IconTwitter />
              </a>
              <a href="https://discord.gg/UF5sbvhFbC" target={'_blank'} rel="noreferrer" title="Discord" className="mx-1">
                <IconDiscord className="mx-1" />
              </a>
              <a href="https://t.me/lizardexchange" target={'_blank'} rel="noreferrer" title="Telegram" className="mx-1">
                <IconTelegram className="mx-1" />
              </a>
              <a href="https://lizardexchange.medium.com/" target={'_blank'} rel="noreferrer" title="Medium" className="mx-1">
                <IconMedium className="mx-1" />
              </a>
            </ToolbarStyled.Socials>
          </>
        )}
        {!isLtXl && (
          <>
            <ToolbarStyled.Logo>
              <Link onClick={() => history.push('/')} rel="noreferrer" title="Lizard">
                <img src={LizardIcon} />
              </Link>
            </ToolbarStyled.Logo>
            <ToolbarStyled.LizardMenu disabledOverflow className='d-flex' theme="dark" mode="horizontal" defaultSelectedKeys={['0']}>
              <ToolbarStyled.LizardMenuItem key="1">
                <a href={Routes.Swap}>
                  Exchange
                </a>
              </ToolbarStyled.LizardMenuItem>
              <ToolbarStyled.LizardMenuItem key="2">
                <a href={Routes.Info} target={'_blank'}>
                  Analytics
                </a>
              </ToolbarStyled.LizardMenuItem>
              <ToolbarStyled.LizardMenuItem key="3">
                <a href={Routes.Bridge} target={'_blank'}>
                  Bridge
                </a>
              </ToolbarStyled.LizardMenuItem>
              <ToolbarStyled.LizardMenuItem key="4">
                <a href={Routes.Docs} target={'_blank'}>
                  Docs
                </a>
              </ToolbarStyled.LizardMenuItem>
            </ToolbarStyled.LizardMenu>
            <ToolbarStyled.Socials className="d-flex align-items-center ms-auto">
              <a href="https://twitter.com/lizardexchange" target={'_blank'} rel="noreferrer" title="Twitter" className="mx-1">
                <IconTwitter />
              </a>
              <a href="https://discord.gg/UF5sbvhFbC" target={'_blank'} rel="noreferrer" title="Discord" className="mx-1">
                <IconDiscord className="mx-1" />
              </a>
              <a href="https://t.me/lizardexchange" target={'_blank'} rel="noreferrer" title="Telegram" className="mx-1">
                <IconTelegram className="mx-1" />
              </a>
              <a href="https://lizardexchange.medium.com/" target={'_blank'} rel="noreferrer" title="Medium" className="mx-1">
                <IconMedium className="mx-1" />
              </a>
            </ToolbarStyled.Socials>
          </>
        )}
      </div>
    </ToolbarStyled.LizardHeader>
  )
};

export default LizardToolbar;
