export enum Routes {
  Home = 'https://lizard.exchange/',
  Swap = 'https://app.lizard.exchange/swap',
  Pool = 'https://app.lizard.exchange/pool',
  Farm = 'https://farm.lizard.exchange',
  Lair = 'https://lair.lizard.exchange',
  Info = 'https://info.lizard.exchange',
  Bridge = 'https://portalbridge.com/#/transfer',
  TaxedToken = 'https://github.com/Lizard-Exchange/reflect-tokens',
  Docs = 'https://docs.lizard.exchange/lizard-exchange-intro/about-lizard-exchange',
  Koth = 'https://docs.lizard.exchange/lizard-exchange-intro/become-a-partner/request-nft-auction-event',
}