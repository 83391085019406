import { Palette, Spacing, MediaQuery } from 'css-in-js';
import { Button, Drawer } from "antd";
import { Menu } from 'antd';
import styled from "styled-components";
import Link from 'antd/lib/typography/Link';
import { Header } from 'antd/lib/layout/layout';

export const MENU_HEIGHT = 80;

const LizardHeader = styled(Header)<{ showMenu: boolean }>`
  background-color: rgba(33, 54, 61, 0.95) !important;
  height: ${MENU_HEIGHT}px;
  padding: 0 ${Spacing.Spacing2};
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  transform: translate3d(0, 0, 0);
  transition: top 0.2s;
  z-index: 20;

  ${MediaQuery.isMedium} {
    padding: 0 ${Spacing.Spacing1};
  }
`

const LizardMenu = styled(Menu)`
  background-color: transparent !important;
  min-width: 34.25rem;
`;

const LizardMenuItem = styled(Menu.Item)` 
  &.ant-menu-item {
    align-items: center;
    display: flex;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 0 !important;
    a {
      color: ${Palette.Gray100} !important;
    }

    &.active {
      background-color: ${Palette.DarkBlue100} !important;
      a {
        color: ${Palette.Gray100} !important;
      }
    }
  }
  &.ant-menu-item-selected,
  &:hover {
    background-color: ${Palette.Lizard} !important;
    a {
      color: ${Palette.Gray100} !important;
    }
  }
`;

const Logo = styled.div`
  align-items: center;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin: .75rem .75rem .75rem 0;
  padding: 0;

  .ant-typography {
    display: flex;
  }

  img {
    height: 3.5rem;
  }
`;

const HamburgerButton = styled.div`
  align-items: center;
  display: flex;
  padding-left: 0;
`;

const SecondaryButton = styled.div`
  margin-left: ${Spacing.Spacing1};
`;

const MainDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper { 
    max-width: 80% !important;
  }
  .ant-drawer-header {
    background-color: ${Palette.Gray900};
    height: ${MENU_HEIGHT}px;
    padding: 0;
  }

  .ant-drawer-title {
    display: flex;
  }

  .ant-drawer-body {
    padding: 0;
  }
`

const SecondaryLogo = styled.img`
  border-radius: 50%;
  display: block;
  height: 8rem;
  margin: 0 auto;
  padding: 1rem;
  width: 8rem;
`

const ToolbarMenu = styled(Menu)`
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: flex-end;
  width: 100%;
`

const LoginButton = styled(Button)`
  align-items: center;
  display: flex;
  margin-left: ${Spacing.Spacing2};

  span {
    font-weight: 700;
    margin-left: ${Spacing.Spacing1};
  }
`

const SiderLink = styled(Link)`
  color: rgba(0, 0, 0, 0.85) !important;

  &.ant-menu-item-selected a {
    color: #1890ff !important;
  }
`

const Socials = styled.div`
  margin-left: ${Spacing.Spacing2};
  > a {
    align-items: center;
    display: flex;
    color: ${Palette.Gray100};

    &:hover {
      color: ${Palette.Lizard};
    }
  }
`

export const ToolbarStyled = {
  LizardHeader,
  LizardMenu,
  LizardMenuItem,
  Logo,
  HamburgerButton,
  SecondaryButton,
  SecondaryLogo,
  MainDrawer,
  ToolbarMenu,
  LoginButton,
  SiderLink,
  Socials
}