import produce, { Draft } from 'immer';
import { Dispatch } from 'react';
import { DispatchObject } from 'domain/dispatch-object';

export type DispatchApp = Dispatch<DispatchObject<AppActions>>;

export enum AppActions {
  IsFetching = 'IS_FETCHING',
  FinishedFetching = 'FINISHED_FETCHING',
  ErrorFetching = 'ERROR_FETCHING',
  SessionExpired = 'SESSION_EXPIRED',
  SetUserToken = 'SET_USER_TOKEN',
  ClearData = 'CLEAR_DATA',
  GetAllScholars = 'GET_ALL_SCHOLARS',
  ShowModalMobile = 'SHOW_MODAL_MOBILE',
}

export interface UserToken {
  _id: string,
  address: string,
  iss: string
}

export interface AppState {
  isFetching: boolean;
  pendingFetches: number;
  sessionExpired: boolean;
  userToken: UserToken;
  fetchScholars: boolean;
  isScholarModalMobileVisible: boolean;
  error: null;
}

export const initialState: AppState = {
  isFetching: false,
  pendingFetches: 0,
  sessionExpired: false,
  userToken: {
    _id: '',
    address: '',
    iss: '',
  },
  fetchScholars: false,
  isScholarModalMobileVisible: false,
  error: null,
};

export const appReducer = produce((draft: Draft<AppState>, { type, payload }: DispatchObject<AppActions>) => {
  switch (type) {
    case AppActions.IsFetching:
      draft.pendingFetches += 1;
      draft.isFetching = true;
      draft.error = null;
      break;
    case AppActions.FinishedFetching:
      draft.pendingFetches -= 1;
      draft.isFetching = false;
      break;
    case AppActions.ErrorFetching:
      draft.pendingFetches = 0;
      draft.isFetching = false;
      draft.error = payload;
      break;
    case AppActions.SessionExpired:
      draft.sessionExpired = true;
      break;
    case AppActions.SetUserToken:
      draft.userToken = payload;
      break;
    case AppActions.GetAllScholars:
      draft.fetchScholars = payload;
      break;
    case AppActions.ShowModalMobile:
      draft.isScholarModalMobileVisible = payload as boolean;
      break;
    case AppActions.ClearData:
      return initialState;
    default:
      throw new Error(`Unknown global action type: ${type}`);
  }
});
