import styled from "styled-components";
import { MediaQuery, Palette, Spacing } from "../../css-in-js";
import HeroBackground from '../../assets/hero-bg-3.png';
import ProcessLine from '../../assets/process-line.png';
import { Button, Col } from "antd";

const HeroSection = styled.div`
  background-color: #f3f7f9;
  background-image: url('${HeroBackground}');
  background-position: right 30% top;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100vh;
  overflow: hidden;
  padding-top: calc(${Spacing.Spacing12}*2);
  position: relative;

  ${MediaQuery.LessThanMedium} {
    padding-top: ${Spacing.Spacing10};
    background-position: right 50% top;
  }

  .laptop-image {
    left: 80px !important;
    max-width: none;
    position: relative;
    width: 450px;
  }

  h2,
  h5 {
    color: ${Palette.Gray100};
  }
  
  .hero-text {
    padding-top: 60px;

    ${MediaQuery.LessThanMedium} {
      text-align: center;
    }
    
    h2 {
      font-size: 70px;
      margin-bottom: 1rem;

      ${MediaQuery.LessThanMedium} {
        font-size: 2.5rem;
        text-align: center;
      }
    }

    h4 {
      color: #75849a;
      font-size: 22px;
    }
  }
`

const LizardButton = styled(Button)`
  color: ${Palette.Gray100};

  &.blue {
    background-color: rgba(21, 61, 111, 0.7);
    border-color: rgba(21, 61, 111, 0.8);
  }

  &.green {
    background-color: #2FB66F;
    border-color: #2FB66F;
  }

  img {
    height: ${Spacing.Spacing3};
    width: ${Spacing.Spacing3};
  }
`

const LizardButton1 = styled(Button)`
  color: ${Palette.Gray100};
  
  &.blue {
    background-color: rgba(21, 61, 111, 0.7);
    border-color: rgba(21, 61, 111, 0.8);
  }
  
  &.green {
    height: auto;
    font-size: 25px !important;
    padding: 8px 20px !important;
    background-color: #2FB66F;
    border-color: #2FB66F;
    color: white;
  }

  img {
    height: ${Spacing.Spacing3};
    width: ${Spacing.Spacing3};
  }
`

const LizardSection = styled.div`
  &.extra-padding {
    padding-bottom: ${Spacing.Spacing9};
    padding-top: ${Spacing.Spacing9};
  }

  &.gradient-bg {
    background-color: #001b22;
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(33, 182, 168, 0.1) 0, #001b22 90%);
    background-position: 0 -100px;
    background-repeat: no-repeat;

    h2, 
    h4 {
      color: ${Palette.Gray100};
    }
  }

  .section-title {
    margin-bottom: ${Spacing.Spacing6};
  }

  h2 {
    font-size: 3rem;
  }

  p {
    font-size: 1.05rem;
    line-height: 1.6;
  }
`

const LizardCol = styled(Col)`
  padding-top: ${Spacing.Spacing4};

  &:last-child {
    .process-step:after {
      display: none;
    }
  }

  ${MediaQuery.LessThanMedium} {
    padding-top: ${Spacing.Spacing1};

    .process-step:after {
      display: none;
    }
  }

  .process-step {
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    height: 100%;
    padding: 35px;
    position: relative;
    text-align: center;

    &:after {
      background: url('${ProcessLine}') no-repeat scroll 0 0 / cover;
      content: "";
      height: 56px;
      position: absolute;
      right: -40%;
      top: -40px;
      width: 275px;
    }

    .process-icon {
      height: 55px;
      margin-bottom: 30px;
      padding-top: 5px;
    }

    h4 {
    margin-bottom: 15px;
    }
    
    p {
      margin-bottom: 0;
    }
  }
`

const LizardPartnerImage = styled.img`
  object-fit: scale-down;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const LizardFooter = styled.div`
  &.extra-padding {
    padding-bottom: ${Spacing.Spacing9};
    padding-top: ${Spacing.Spacing12};
  }

  .logo-footer {
    height: 5rem;
  }

  span {
    color: #acb9cc;
  }

  p {
    margin-bottom: 20px;
  }
  
  .widget-title {
    margin-bottom: 30px;
    font-size: 20px;
  }
  
  ul {
    list-style: none;
    padding-left: 0;

    a {
      color: #75849a;
      display: block;
      font-size: 16px;
      margin-bottom: 12px;
    }
    
    li:last-child a {
      margin-bottom: 0;
    }
  }
`

export const HomeStyled = {
  HeroSection,
  LizardButton,
  LizardSection,
  LizardCol,
  LizardPartnerImage,
  LizardFooter,
  LizardButton1
}