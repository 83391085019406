import React, { FC, useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { HomeStyled } from './Home.styled';
import LizardIcon from '../../assets/logo.png';
import LizardSvg from '../../assets/lizard_white.svg';
import CGClogo from '../../assets/partners/CGC.png';
import IconDiscord from 'components/icons/IconDiscord';
import IconMedium from 'components/icons/IconMedium';
import IconTelegram from 'components/icons/IconTelegram';
import IconTwitter from 'components/icons/IconTwitter';
import IconGithub from '../../components/icons/IconGithub/index';
import IconCoinMarketcap from 'components/icons/IconCoinMarketcap';
import { LizardDataResponse } from 'domain/lizard-data';

function formatNumber(number: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });


  return formatter.format(number);
}


const Home: FC = () => {

  const [totalVolume, setTotalVolume] = useState(0);
  const [tvl, setTvl] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch("https://graph.lizard.exchange/subgraphs/name/Lizard/exchange", {
          "body": "{\"operationName\":\"lizardDayDatas\",\"variables\":{\"startTime\":1644278400,\"skip\":0},\"query\":\"query lizardDayDatas($startTime: Int!, $skip: Int!) {\\n  lizardDayDatas(first: 1000, skip: $skip, where: {date_gt: $startTime}, orderBy: date, orderDirection: asc) {\\n    id\\n    date\\n    totalVolumeUSD\\n    dailyVolumeUSD\\n    dailyVolumeETH\\n    totalLiquidityUSD\\n    totalLiquidityETH\\n    __typename\\n  }\\n}\\n\"}",
          "method": "POST",
        }).then(res => res.json()) as LizardDataResponse;


        const [totalVolumen, currentTvl] = response.data.lizardDayDatas.reduce((acc, obj) => {
          return [acc[0] + Number(obj.dailyVolumeUSD), Number(obj.totalLiquidityUSD)];
        }, [0, 0])
        setTotalVolume(40000000);
        setTvl(27000);
      } catch (error) {
        console.log(error);
      }
    };

    loadData();
  }, [])

  return (
    <Layout>
      <HomeStyled.HeroSection>
        <div className="container">
          <Row gutter={[16, 16]} justify="center">
            <Col className="hero-text" xs={24} sm={24} md={12} lg={12}>
              <h2>Lizard Exchange</h2>
              <h4 className="text-white mb-4">
                An automated market-making (AMM) decentralized trading platform
                with low fees, high-speed transactions.
              </h4>
              <HomeStyled.LizardButton1 className="green" type="primary" shape="round" size="large" href="https://app.lizard.exchange/">
                Go to Emerald DAPP
              </HomeStyled.LizardButton1>
              {' '} {' '}
              <HomeStyled.LizardButton1 className="green" type="primary" shape="round" size="large" href="https://sapphire.lizard.exchange/">
                Go to Sapphire DAPP
              </HomeStyled.LizardButton1>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} className="d-flex align-items-center">
              <img
                src={LizardIcon}
                className="w-75 m-auto d-block"
                alt="Lizard Exchange Logo"
              />
            </Col>
          </Row>
        </div>
      </HomeStyled.HeroSection>
      <HomeStyled.LizardSection className="extra-padding gradient-bg">
        <div className="container text-white">
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="feature-content text-center">
                <div className="numberUSD">
                  {formatNumber(27000)}
                </div>
                <h4>TVL</h4>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="feature-content text-center">
                <div className="numberUSD">
                  {formatNumber(40000000)}
                </div>
                <h4>TOTAL VOLUME</h4>
              </div>
            </Col>
          </Row>
        </div>
      </HomeStyled.LizardSection>
      <HomeStyled.LizardSection className="extra-padding" id="what-is-lizard">
        <div className="container">
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={12} lg={12}>
              <h2 className="text-center">What is Lizard?</h2>
              <h5 className="text-justify">
                Lizard Exchange is an automated market-making (AMM) decentralized
                trading platform with 0.2% fees, high-speed transactions, providing
                liquidity and enabling peer-to-peer transactions on Sapphire Paratime on Oasis Network.
              </h5>
              <h5 className="text-justify">
              We believe in the core values of decentralization and uncensorability. Privacy is a human right and no organization or institution should be able to interfere with that.
                <br />
                <br />
                Lizard Exchange launched on Emerald Paratime on February 2022. We hit a record 10M in TVL and processed over 40M USD in transaction volume. We deployed the first decentralized exchange on Sapphire Paratime in November 2023.
              </h5>
            </Col>
          </Row>
        </div>
      </HomeStyled.LizardSection>

      <HomeStyled.LizardSection className="extra-padding gradient-bg">
        <div className="container text-white">
          <div className="section-title text-center">
            <h2>Why Lizard exchange?</h2>
          </div>

          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="feature-content">
                <h4>2 years in the Oasis Ecosystem</h4>
                <p>
                 Launched on Emerald in February 2022 and Sapphire in November 2023. We survived the bear market and are ready for the next bull run.
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="feature-content">
                <h4>Respect for our community and holders</h4>
                <p>
                  We respect our commitment to being created solely
                  for the benefit of users. We are easily accesible on our telegram group. We respect all views and suggestions.
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="feature-content">
                <h4>Lowest fees and ease to use</h4>
                <p>
                  Trade directly from wallet to wallet, without any third party
                  account or releasing custody of your tokens. Do not pay high fees to centralized exchanges. Do not wait for your funds to be released. Do not worry about your funds being stolen.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </HomeStyled.LizardSection>

      <HomeStyled.LizardSection className="extra-padding">
        <div className="container">
          <div className="section-title text-center">
            <h2>Roadmap</h2>
            <p>
              The roadmap explains our project's goals, so by knowing them, you
              know what to expect from us in the future
            </p>
          </div>
          <Row gutter={[16, 16]} justify="center">
            <HomeStyled.LizardCol xs={24} sm={24} md={12} lg={8}>
              <div className="process-step">
                <h4>Transition Phase</h4>
                <p>Preparing for a smooth launch in Sapphire</p>
                <p>We prepare a migration with a proof of burn.</p>
                
                <p>The dex experience in Sapphire should be smooth.</p>
              </div>
            </HomeStyled.LizardCol>
            <HomeStyled.LizardCol xs={24} sm={24} md={12} lg={8}>
              <div className="process-step">
                <h4>Launch Phase</h4>
                <p>Lizard Fair Token Launch</p>
                <p>Initial Farms and incentives</p>
                <p>Volume building</p>
                <p>General Community Feedback & Suggestions</p>
              </div>
            </HomeStyled.LizardCol>
            <HomeStyled.LizardCol xs={24} sm={24} md={12} lg={8}>
              <div className="process-step">
                <h4>More information</h4>
                <p>
                  <a
                    href="https://docs.lizard.exchange/"
                    className="site-btn sb-gradients mt-4"
                    target="_blank"
                  >
                    Go to documentation
                  </a>
                </p>
              </div>
            </HomeStyled.LizardCol>
          </Row>
        </div>
      </HomeStyled.LizardSection>

      <HomeStyled.LizardSection className="extra-padding gradient-bg">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="text-white">Our Partners</h2>
          </div>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={8} sm={6} md={6} lg={4}>
              <a
                className="flex justify-center items-center"
                href="https://cryptoguys.club"
                target="_blank"
                title="CryptoGuys Club"
              >
                <HomeStyled.LizardPartnerImage src={CGClogo} className="w-100" alt="CryptoGuys Club" />
              </a>
            </Col>
           
          </Row>
        </div>
      </HomeStyled.LizardSection>

      <HomeStyled.LizardFooter className="extra-padding">
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={14}>
              <img src={LizardIcon} className="mb-4 logo-footer" alt="Lizard" />
              <div>
                <span>
                  Copyright &copy; {new Date().getFullYear()} - All rights reserved
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={5}>
              <h5 className="widget-title">Quick Links</h5>
              <ul>
                <li>
                  <a href="https://app.lizard.exchange" target="_blank">App</a>
                </li>
                <li>
                  <a href="https://docs.lizard.exchange" target="_blank"
                  >Documentation</a
                  >
                </li>
                <li>
                  <a href="https://info.lizard.exchange" target="_blank"
                  >Analytics</a
                  >
                </li>
              </ul>
            </Col>
            <Col xs={24} sm={12} md={6} lg={5}>
              <h5 className="widget-title">Follow Us</h5>
              <ul className="d-flex">
                <li>
                  <a
                    href="https://github.com/Lizard-Exchange/"
                    target={'_blank'}
                    rel="noreferrer"
                    title="Github"
                    className="mx-1"
                  >
                    <IconGithub className="mx-1" style={{ fontSize: '1.25rem', color: '#171515' }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/lizardexchange"
                    target={'_blank'}
                    rel="noreferrer"
                    title="Twitter"
                    className="mx-1"
                  >
                    <IconTwitter style={{ fontSize: '1.25rem', color: '#49a7f3' }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.gg/UF5sbvhFbC"
                    target={'_blank'}
                    rel="noreferrer"
                    title="Discord"
                    className="mx-1"
                  >
                    <IconDiscord className="mx-1" style={{ fontSize: '1.25rem', color: '#5865F2' }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/lizardexchange"
                    target={'_blank'}
                    rel="noreferrer"
                    title="Telegram"
                    className="mx-1"
                  >
                    <IconTelegram className="mx-1" style={{ fontSize: '1.25rem', color: '#0088cc' }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://lizardexchange.medium.com/"
                    target={'_blank'}
                    rel="noreferrer"
                    title="Medium"
                    className="mx-1"
                  >
                    <IconMedium className="mx-1" style={{ fontSize: '1.25rem', color: '#171515' }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://api.lizardexchange.com/cmc/listing"
                    target={'_blank'}
                    rel="noreferrer"
                    title="CoinMarketcap Pending
                    Request" className="mx-1"
                  >
                    <IconCoinMarketcap className="mx-1" style={{ fontSize: '1.25rem', color: '#171515' }} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </HomeStyled.LizardFooter>
    </Layout>
  )
};

export default Home;
